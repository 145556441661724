<script lang="ts">
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import blockState from '$src/lib/stores/block-state'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import DOMPurify from 'dompurify'
	import debounce from 'lodash-es/debounce'
	import { onDestroy } from 'svelte'
	import { sanitizeAllTagsHtml } from '$src/lib/utils/sanitize'
	import { browser } from '$app/environment'

	interface Props {
		title: string
		description?: string | null
		size?: 'small' | 'large'
		iconUrl?: string
		bgColor?: string
		imageUrl?: string | null
		placeId?: string | null
		isClickable?: boolean
		isTruncated?: boolean
		onclick?: () => void
	}

	let {
		title,
		description = null,
		size = 'small',
		iconUrl = '/visuals/icons/fork-knife.svg',
		bgColor = 'bg-red-200',
		imageUrl = null,
		placeId = null,
		isClickable = true,
		isTruncated = true,
		onclick,
	}: Props = $props()

	let sanitizedDescription = $derived(
		sanitizeAllTagsHtml(fixTitleCharacters(description), browser)?.trim()?.slice(0, 100),
	)

	let fixedTitle = $derived(fixTitleCharacters(title))

	const debouncedSetHoverState = debounce(
		(id: string | null) => {
			if (id != null) blockState.setHoveringPlaceId(placeId)
		},
		100,
		{ leading: true, trailing: false }, // Execute on the leading edge for better UX with hover
	)

	// Then update the handler function
	function handleSetHoverState() {
		debouncedSetHoverState(placeId)
	}

	onDestroy(() => {
		debouncedSetHoverState.cancel()
	})

	function handleUnsetHoverState() {
		if (placeId != null) blockState.cleartHoveringPlaceId()
	}
</script>

<button
	disabled={!isClickable}
	onclick={stopPropagation(() => {
		onclick?.()
	})}
	onmouseenter={handleSetHoverState}
	onmouseleave={handleUnsetHoverState}
	class={`flex items-center touch-manipulation ${
		isClickable ? 'group cursor-pointer' : 'cursor-not-allowed'
	}  w-full py-1 overflow-x-hidden truncate mr-4`}
>
	<div
		class={`${
			size === 'large' ? 'w-12 h-12' : 'w-8 h-8'
		} flex items-center justify-center rounded-md object-cover flex-shrink-0 ${bgColor}`}
	>
		{#if imageUrl != null}
			<img
				class={`${size === 'large' ? 'w-12 h-12' : 'w-8 h-8'} rounded-md image-cover`}
				src={imageUrl}
				alt={title}
			/>
		{:else}
			<img
				class={`${size === 'large' ? 'w-6 h-6' : 'w-5 h-5'} p-auto ${isClickable ? '' : 'opacity-50'}`}
				src={iconUrl}
				alt={title}
			/>
		{/if}
	</div>
	<div class={`pl-4 text-start w-full ${isClickable ? '' : 'opacity-30'}`}>
		<div
			class={`text-sm font-medium dark:text-brand-gray-2 group-hover:dark:text-white text-black w-full ${
				isTruncated ? 'truncate pr-8' : 'whitespace-break-spaces'
			}`}
		>
			{@html fixedTitle}
		</div>
		<p
			class={`text-xs dark:text-brand-gray-3 group-hover:dark:text-brand-gray-2 text-brand-gray-5 w-full ${
				isTruncated ? 'truncate pr-8' : 'whitespace-break-spaces'
			}`}
		>
			{@html sanitizedDescription ?? 'No description available'}
		</p>
	</div>
</button>
